body{
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.mapSearchInputs {
  padding: 1em 2em 1em 2em;
}

.verticalGroup {
  margin-bottom: 1em;
}

.hidden {
  display: none !important;
}

.statusBanner {
  width: 100%;
  display: block;
  padding: 1em;
}

.ready {
  color: #FFFFFF;
  background-color: #28a745;
}

.busy {
  color: #212529;
  background-color: #ffc107;
}

.error {
  color: #FFFFFF;
  background-color: #dc3545;
}

li.autocomplete-item{
  padding: 0px;
}

div.autocomplete-item{
  padding: 0.5em;
}

.highlightSuggestion {
  background-color: #98fb98 !important;
}

.minmax {
  height: 2em;
  margin-right: 0.25em;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, 1);
}

.hero-wrap{
  position: relative;
}

.hero-wrap::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-image: url('../public/hero.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.hero {
  position: relative; 
  padding: 3em;
}

#explore-dropdown, #api-dropdown {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #6c757d;
}

nav.navbar {
  z-index: 10000;
  border-bottom: 1px solid black;
}

@media (min-width: 992px) {
  .scrollit {
    overflow: scroll;
  }

  .plotting-scrollit {
    overflow: scroll;
    height: 55vh;
  }

  .aboutBlock{
    padding: 3em 12em 3em 12em;
  }
}

@media (max-width: 992px) {
  .tablewidth {
    max-width: 100vw;
    overflow: visible;
    overflow-x: scroll;
  }

  body {
    overflow-y: scroll;
  }

  .aboutBlock{
    padding: 3em;
  }
}

.invalid {
  border: 1px solid red;
}

.wide-tooltip .tooltip-inner {
  max-width: 50%;
}

.fa-question-circle {
  padding-right: 0.25em;
}

.form-section {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 1em;
}